import * as React from 'react';
import { graphql } from 'gatsby';
import { Box, Text } from '@chakra-ui/react';
import SeoComponent from '../../components/SeoComponent';

/* SECTIONS */
import ProductOverview from '../../sections/ProductOverview';
import ProductInfo from '../../sections/ProductInfo';
import ChooseState from '../../sections/ChooseState';
import RelatedProducts from '../../sections/RelatedProducts';
import CollectionRecommendations from '../../sections/CollectionRecommendations';

function ProductPage(props) {
  const { pageContext, data } = props;
  
  const productData = pageContext.product;
  const seoImg = data.product.seoImage?.image || data.product?.collectionImage;
  
  const [stateSelected, setStateSelected] = React.useState(0);

  /* FIND DEFAULT STATE */
  const defaultStateFound = productData.infoPerState.find(item => item.isDefault);
  const [defaultState, setDefaultState] = React.useState(defaultStateFound);

  /* CATEGORY AND SUBCATEGORY PRODUCTS LIST */
  const similarProducts = pageContext.categoryName 
    ? data.byCategory.nodes
    : pageContext.subcategoryName 
      ? data.bySubcategory.nodes
      : null

  // console.log('ProductPage: ', similarProducts);
  
  return (
    <>
      <SeoComponent
        title={productData.seoTitle} 
        description={productData.seoDescription}
        imageUrl={seoImg?.asset?.url}
      />
      
      <Box>
        {/* PRODUCT OVERVIEW */}
        <ProductOverview 
          productData={productData} 
          stateSelected={stateSelected} 
          setStateSelected={setStateSelected} 
          defaultState={defaultState}
          setDefaultState={setDefaultState}
          shopAllRoute={pageContext.shopAllRoute}
        />

        {/* PRODUCT INFO */}
        <ProductInfo productData={productData} stateSelected={stateSelected} defaultState={defaultState} />

        {/* CHOOSE STATE */}
        {/* <ChooseState 
          productData={productData} 
          defaultImage={data.allSanityImageAsset.nodes} 
          stateSelected={stateSelected} 
        /> */}

        {/* RELATED PRODUCTS */}
        {/* <RelatedProducts 
          productData={productData} 
          relatedProducts={similarProducts}
          productsBaseRoute={pageContext.productsBaseRoute}
        /> */}

        {/* COLLECTION RECOMMENDATIONS */}
        {/* <CollectionRecommendations 
          category={pageContext.product.category} 
          collections={pageContext.categories} 
          productPagesPath={pageContext.productPagesPath}
        /> */}
      </Box>
    </>
  );
}

export const query = graphql`
  query PRODUCT_QUERY ($id: String!, $categoryName: String, $subcategoryName: String) {

    allSanityImageAsset(filter: {name: {eq: "choose state default image 1"}}) {
      nodes {
        name
        image {
          asset {
            gatsbyImageData
          }
        }
      }
    }
    byCategory: allSanityProduct(filter: {category: {slug: {current: {eq: $categoryName}}}}) {
      nodes {
        isPublic
        name
        seoTitle
        seoDescription
        seoImage {
          image {
            asset {
              url
            }
          }
        }
        slug {
          current
        }
        collectionImage {
          asset {
            gatsbyImageData(placeholder: NONE, width: 276, height: 276)
          }
        }
        category {
          ctaRoute {
            route
          }
          slug {
            current
          }
        }
        subcategory {
          ctaRoute {
            route
          }
          slug {
            current
          }
        }
        infoPerState {
          isDefault
          useDefaultDetails
          useDefaultCompoundInfo
          useDefaultAdditionalInfo
          _rawDetails
          _rawAdditionalInfo
          warning
          state {
            name
            abbrevation
            slug {
              current
            }
          }
          productImage {
            asset {
              gatsbyImageData(width: 668, height: 535, fit: MAX, placeholder: NONE)
            }
          }
          details {
            children {
              _type
              marks
              text
            }
            _type
            list
            style
          }
          additionalInfo {
            _type
            style
            list
            children {
              text
              marks
            }
          }
          compoundInformation {
            compound {
              name
              slug {
                current
              }
            }
            compoundPerPKG
            compoundPerPiece
          }
        }
        effects {
          name
          slug {
            current
          }
        }
        lineages {
          name
          slug {
            current
          }
        }
        nutriInfo
        ingredients
        unitsPerPackage
        durationImage {
          asset {
            gatsbyImageData(width: 172, height: 111, placeholder: NONE)
          }
        }
        typeOfStore {
          name
          slug {
            current
          }
        }
      }
    }
    bySubcategory: allSanityProduct(filter: {subcategory: {slug: {current: {eq: $subcategoryName}}}}) {
      nodes {
        isPublic
        name
        seoTitle
        seoDescription
        seoImage {
          image {
            asset {
              url
            }
          }
        }
        slug {
          current
        }
        collectionImage {
          asset {
            gatsbyImageData(placeholder: NONE, width: 276, height: 276)
          }
        }
        category {
          ctaRoute {
            route
          }
          slug {
            current
          }
        }
        subcategory {
          ctaRoute {
            route
          }
          slug {
            current
          }
        }
        infoPerState {
          isDefault
          useDefaultDetails
          useDefaultCompoundInfo
          useDefaultAdditionalInfo
          _rawDetails
          _rawAdditionalInfo
          warning
          state {
            name
            abbrevation
            slug {
              current
            }
          }
          productImage {
            asset {
              gatsbyImageData(width: 668, height: 535, fit: MAX, placeholder: NONE)
            }
          }
          details {
            children {
              _type
              marks
              text
            }
            _type
            list
            style
          }
          additionalInfo {
            _type
            style
            list
            children {
              text
              marks
            }
          }
          compoundInformation {
            compound {
              name
              slug {
                current
              }
            }
            compoundPerPKG
            compoundPerPiece
          }
        }
        effects {
          name
          slug {
            current
          }
        }
        lineages {
          name
          slug {
            current
          }
        }
        nutriInfo
        ingredients
        unitsPerPackage
        durationImage {
          asset {
            gatsbyImageData(width: 172, height: 111, placeholder: NONE)
          }
        }
        typeOfStore {
          name
          slug {
            current
          }
        }
      }
    }
    product: sanityProduct(id: {eq: $id }) {
      seoImage {
        image {
          asset {
            url
          }
        }
      }
      collectionImage {
        asset {
          url
        }
      }
    }

  }
`;

export default ProductPage;
