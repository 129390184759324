import * as React from "react";
import {
  Box,
  Text,
  Flex,
  Collapse,
  UnorderedList,
  ListItem,
  Image,
} from "@chakra-ui/react";
import { MdOutlineAdd } from "react-icons/md";
import { RiSubtractLine } from "react-icons/ri";
import TextStrokeHeader from "../../components/TextStrokeHeader";
import OneColumnText from "../BlogPostSections/OneColumnText";
import { motion } from "framer-motion";

import allVegan from "../../images/all-vegan.png";
import glutenFree from "../../images/gluten-free.png";
import orgSweetened from "../../images/org-sweetened.png";

function ProductInfo(props) {
  const { productData, stateSelected, defaultState } = props;
  const {
    infoPerState,
    ingredients,
    _rawNutritionalInfo,
    nutriInfo,
    category,
  } = productData;

  // console.log('ProductInfo: ', productData);

  /* STYLES */
  const oneColumnTextStyles = {
    fontColor: "white",
    fontSizes: {
      normal: { base: "12px", md: "16px" },
      h4: { base: "14px", md: "18px" },
      h3: { base: "16px", md: "20px" },
      h2: { base: "18px", md: "22px" },
      h1: { base: "20px", md: "24px" },
    },
    fontWeights: {
      normal: "400",
      strong: "600",
    },
    lineHeights: {
      normal: { base: "25px", md: "30px" },
      h4: { base: "27px", md: "32px" },
      h3: { base: "29px", md: "34px" },
      h2: { base: "31px", md: "36px" },
      h1: { base: "33px", md: "38px" },
    },
    letterSpacings: {
      normal: { base: "0px", md: "0px" },
      h4: { base: "0px", md: "0px" },
      h3: { base: "0px", md: "0px" },
      h2: { base: "0px", md: "0px" },
      h1: { base: "0px", md: "opx" },
    },
    textAlign: { base: "left", md: "left" },
    mbSection: { base: "0px", md: "0px" },
    mbContainers: { base: "25px", md: "25px" },
    hover: { borderColor: "primary" },
    linkFontStyle: "italic",
    listsLeftMargin: { base: "20px", md: "20px" },
  };

  /* ATTRIBUTES */
  const attributes = [
    {
      img: allVegan,
      name: "all vegan",
    },
    {
      img: orgSweetened,
      name: "organically sweetened",
    },
  ];

  /* UPDATE ADDITIONAL INFO WHEN STATE CHANGES */
  const [additionalInfoToShow, setAdditionalInfoToShow] = React.useState(null);
  const [ingredientsToShow, setIngredientsToShow] = React.useState(null);

  React.useEffect(() => {
    if (infoPerState[stateSelected]?.useDefaultAdditionalInfo) {
      defaultState._rawAdditionalInfo
        ? setAdditionalInfoToShow(defaultState._rawAdditionalInfo)
        : null;
    } else {
      infoPerState[stateSelected]?._rawAdditionalInfo
        ? setAdditionalInfoToShow(
            infoPerState[stateSelected]._rawAdditionalInfo
          )
        : null;
    }

    if (infoPerState[stateSelected]?.ingredients === null) {
      setIngredientsToShow(productData.ingredients);
    } else {
      setIngredientsToShow(infoPerState[stateSelected]?.ingredients);
    }

    // infoPerState[stateSelected]._rawAdditionalInfo ? setAdditionalInfoToShow(infoPerState[stateSelected]._rawAdditionalInfo) : null;
  }, [stateSelected]);

  return (
    <Box w="100%" h="auto" mt={{ base: "44px", md: "37px" }}>
      <Flex
        maxW={{ base: "90%", ms: "84%", mds: "84%", lg: "84%", xl: "1200px" }}
        mx={{ base: "auto" }}
        direction="column"
      >
        {/* HEADER */}
        <TextStrokeHeader
          text={"more info"}
          fontSize={{
            base: "27.78vw",
            ssm: "100px",
            ms: "13vw",
            msx: "13.04vw",
            md: "13.09vw",
            mds: "13.1vw",
            lg: "13.17vw",
            lgs: "13.2vw",
            xl: "190px",
          }}
          lineHeight={{
            base: "21.67vw",
            ssm: "78px",
            ms: "13.9vw",
            msx: "13.9vw",
            md: "13.9vw",
            mds: "13.9vw",
            lg: "13.9vw",
            lgs: "13.9vw",
            xl: "200px",
          }}
          letterSpacing={{ base: "-0.95px" }}
          mb={{
            base: "-3.2vw",
            ssm: "-3.2vw",
            ms: "-3.2vw",
            msx: "-3.2vw",
            md: "-3.2vw",
            mds: "-3.2vw",
            lg: "-3.2vw",
            lgs: "-3.2vw",
            xl: "-45px",
          }}
          textStroke={"1px"}
        />

        {/* CONTENT */}
        <Flex
          w="100%"
          bgColor="primaryDarker"
          py={{ base: "65px", md: "80px", lg: "129px" }}
          px={{
            base: "7.23vw",
            ms: "7.5vw",
            md: "6vw",
            mds: "7.4vw",
            lg: "7.48vw",
            lgs: "7.51vw",
            xl: "108px",
          }}
          // px={{base:'26px', md:'59px', lg:'108px'}}
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between"
          // alignItems={nutriInfo && category.slug.current !== 'tinctures' ? 'flex-start' : 'center'}
          style={{ zIndex: 3 }}
        >
          {/* LEFT CONTENT */}
          <Box
            // w={{base:'100%', md:'50%', mds:'40%', lg:'42%', lgs:'38%',  xl:'292px'}}
            maxW={{ base: "100%", md: "292px" }}
          >
            {/* TEXT */}
            <Text
              color="white"
              fontSize={{ base: "40px", md: "47px", lg: "57px" }}
              fontWeight="bold"
              letterSpacing="0"
              lineHeight="1"
              textAlign={{ base: "center", md: "left" }}
              // border="1px"
              // borderColor="white"
            >
              {"Everything you need to know."}
            </Text>

            {/* ATTRIBUTES */}
            <Flex
              w={{ base: "100%", md: "90%" }}
              pt={{ base: "49px", md: "38px" }}
              pb={{ base: "49px", md: "0" }}
              justifyContent="space-between"
              alignItems={{ base: "flex-start", md: "start" }}
              ml={{
                base: "0",
                md: "-1.25vw",
                lg: "-1.15vw",
                lgs: "-1.1vw",
                xl: "-15px",
              }}
            >
              {attributes.map((att, indx) => {
                return (
                  <Attribute
                    key={indx}
                    img={att.img}
                    name={att.name}
                    indx={indx}
                  />
                );
              })}
            </Flex>
          </Box>

          {/* RIGHT CONTENT */}
          <Flex
            w={{
              base: "100%",
              md: "36.58vw",
              mds: "40.57vw",
              lg: "42.9vw",
              lgs: "42.95vw",
              xl: "618px",
            }}
            // maxW={{base:'100%', md:'618px'}}
            borderTop="1px"
            borderColor="blue"
            direction="column"
          >
            {/* INGREDIENTS */}
            <CollapsableContent text={"ingredients"}>
              {ingredients && (
                <Text
                  color="white"
                  fontSize={{ base: "12px", md: "16px" }}
                  textAlign="left"
                  pr={{ base: "0px", md: "15px" }}
                >
                  {ingredientsToShow}
                </Text>
              )}

              {!ingredients && (
                <Text
                  color="white"
                  fontSize={{ base: "12px", md: "14px" }}
                  lineHeight="1"
                >
                  {"No ingredients were added yet"}
                </Text>
              )}
            </CollapsableContent>

            {/* NUTRITIONAL INFO */}
            {nutriInfo && category.slug.current !== "tinctures" && (
              <CollapsableContent text={"nutritional information"}>
                <UnorderedList>
                  {nutriInfo.split("\n").map((block, indx) => {
                    const textArr = block.split(",");
                    // console.log('use nutriInfo', textArr);

                    return (
                      <React.Fragment key={indx}>
                        {textArr[0] !== "" && (
                          <ListItem
                            listStyleType="none"
                            _last={{ borderColor: "transparent" }}
                            py={{ base: "10px", md: "15px" }}
                            ml={{ base: "-15px", md: "-15px" }}
                            borderBottom="1px"
                            borderColor="grayBG"
                          >
                            <Box
                              w={{ base: "100%", md: "95%" }}
                              display="grid"
                              gridTemplateColumns={{
                                base: "70% 30%",
                                md: "80% 20%",
                              }}
                            >
                              <Text
                                color="white"
                                fontSize={{ base: "12px", md: "16px" }}
                                lineHeight="1"
                              >
                                {`${textArr[0]}`}
                              </Text>

                              <Text
                                color="white"
                                fontSize={{ base: "12px", md: "16px" }}
                                lineHeight="1"
                              >
                                {`${textArr[1] || " "}`}
                              </Text>
                            </Box>
                          </ListItem>
                        )}
                      </React.Fragment>
                    );
                  })}
                </UnorderedList>
              </CollapsableContent>
            )}

            {/* ADDITIONAL INFO */}
            <CollapsableContent
              text={"additional info"}
              pr={{ base: "0px", md: "15px" }}
            >
              {additionalInfoToShow && (
                <OneColumnText
                  _rawOneColumnText={additionalInfoToShow}
                  styles={oneColumnTextStyles}
                />
              )}
            </CollapsableContent>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}

const Attribute = (props) => {
  const { img, name, indx } = props;

  return (
    <Box w="100%" >
      {/* IMG */}
      <Box
        w={{ base: "49px", md: "55px" }}
        h={{ base: "49px", md: "55px" }}
        borderRadius="full"
        // border="2px"
        // borderColor="#00A6CE"
        mx="auto"
        mb={{ base: "17px", md: "17px" }}
      >
        <Image
          src={img}
          boxSize={{ base: "49px", md: "55px" }}
          objectFit="contain"
        />
      </Box>

      {/* TEXT */}
      <Text
        w={ indx === 0 ? {base:'49px'}: "100%"}
        color="white"
        fontSize={{ base: "12px", md: "12px" }}
        fontWeight="800"
        letterSpacing="0"
        
        lineHeight="15px"
        textAlign="center"
        textTransform="uppercase"
        mx="auto"
      >
        {name}
      </Text>
    </Box>
  );
};

const CollapsableContent = (props) => {
  const { text, children, pr } = props;
  const [show, setShow] = React.useState(false);

  return (
    <Box w="100%" borderBottom="1px" borderColor="blue">
      <Flex
        w="100%"
        py={{ base: "40px", md: "40px" }}
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setShow(!show)}
        role="button"
      >
        <Text
          color="white"
          fontSize={{ base: "14px", md: "20px" }}
          fontWeight="800"
          letterSpacing="6px"
          lineHeight={{ base: "1.5", md: "1" }}
          textTransform="uppercase"
        >
          {text}
        </Text>

        <Box position="relative">
          <RiSubtractLine
            style={{ width: "20px", height: "20px", color: "white" }}
          />

          <Box position="absolute" top="0">
            <motion.div
              animate={{
                rotate: show ? 0 : 90,
                transition: { duration: 0.65 },
              }}
            >
              <RiSubtractLine
                style={{ width: "20px", height: "20px", color: "white" }}
              />
            </motion.div>
          </Box>
        </Box>
      </Flex>

      <Collapse in={show}>
        <Box
          w="100%"
          pb={{ base: "20px", md: "20px" }}
          pr={pr || { base: "0" }}
        >
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};

export default ProductInfo;
